/* eslint-disable sort-keys */
import { apiFetch } from '@api/base';
import { notify } from '@lib/notify';
import type { TAction, TDispatch } from '@models/types';

import { DaftarNamaPenumpangActionType } from './types';

import type { DaftarNamaPenumpangAction, DaftarNamaPenumpangModel } from './types';

const DUMMY_DAFTAR_NAMA_PENUMPANG = [
  {
    nama_penumpang: 'Aji',
    no: 1,
    tanggal_lahir: '18-12-2000'
  },
  {
    nama_penumpang: 'Anna',
    no: 2,
    tanggal_lahir: '18-12-1999'
  }
];

const DaftarNamaPenumpangDefault: DaftarNamaPenumpangModel = {
  syaratMain: {
    data: {
      booking_fee: [],
      down_payment: [],
      full_payment: [],
      full_payment_approval: [],
      insert_passenger: [],
      issued_ticket: [],
      materialisasi: [],
      order_reduction: [],
      others: [],
      ticket_terms: [],
      time_limit: []
    },
    debug: {
      airline: '',
      bookingToDeparture: '',
      confirmDate: '',
      departureDate: '',
      inventoryType: '',
      price: '',
      supplier: ''
    },
    error: false,
    message: ''
  },
  OrderSummary: {
    data: {
      flight_detail: {
        departure_summary: [
          {
            date: '',
            flight_number: ''
          }
        ],
        returning_summary: [
          {
            date: '',
            flight_number: ''
          }
        ]
      },
      order_number: '',
      payment: {
        booking_fee: 0,
        downpayment_1: 0,
        downpayment_2: 0,
        full_payment: 0
      },
      pnr: '',
      total_passenger: 1
    },
    error: false,
    message: ''
  },
  daftarNamaPenumpang: [],
  penumpang: {
    data: [],
    error: false,
    message: ''
  },
  confirm: []
};

const DaftarNamaPenumpangReducer = (state: DaftarNamaPenumpangModel = DaftarNamaPenumpangDefault, action: DaftarNamaPenumpangAction): DaftarNamaPenumpangModel => {
  switch (action.type) {
    case DaftarNamaPenumpangActionType.GetDaftarNamaPenumpang:
      return { ...state, daftarNamaPenumpang: action.data };
    case DaftarNamaPenumpangActionType.GetSyaratKetentuan:
      return { ...state, syaratMain: action.data };
    case DaftarNamaPenumpangActionType.GetOrderSummary:
      return { ...state, OrderSummary: action.data };
    case DaftarNamaPenumpangActionType.GetPenumpang:
      return { ...state, penumpang: action.data };
    case DaftarNamaPenumpangActionType.GetConfirm:
      return { ...state, confirm: action.data };

    default:
      return { ...state };
  }
};

const DaftarNamaPenumpangCommand = {
  getConfirm: (payload: string, token: string): Promise<string> => {
    return apiFetch(token).post('/order/1.0/agent/request-issue-ticket', { number: payload })
      .then((response) => {
        if (response.status === 200) {
          notify.success('Success');

          return 'ok';
        }

        return 'err';
      }).catch(() => {
        notify.error('Error');

        return 'err';
      });
  },
  getDataSyaratPesanan: (token: string, payload: unknown): TAction<DaftarNamaPenumpangAction, void> => {
    return async (dispatch: TDispatch<DaftarNamaPenumpangAction>) => {
      const response = await apiFetch(token).post(`/order/1.0/agent/generate-tac-by-order-number`, payload);
      if (response.status === 200) {
        dispatch({
          data: response.data,
          type: DaftarNamaPenumpangActionType.GetSyaratKetentuan
        });
      }
    };
  },
  getOrderSummary: (token: string, id: string): TAction<DaftarNamaPenumpangAction, void> => {
    return async (dispatch: TDispatch<DaftarNamaPenumpangAction>) => {
      const response = await apiFetch(token).get(`/order/1.0/agent/order-summary?number=${id}`);
      if (response.status === 200) {
        dispatch({
          data: response.data,
          type: DaftarNamaPenumpangActionType.GetOrderSummary
        });
      }
    };
  },
  getPenumpang: (token: string, id: string): TAction<DaftarNamaPenumpangAction, void> => {
    return async (dispatch: TDispatch<DaftarNamaPenumpangAction>) => {
      const response = await apiFetch(token).get(`/order/1.0/agent/get-lg-passengers?number=${id}`);
      if (response.status === 200) {
        dispatch({
          data: response.data,
          type: DaftarNamaPenumpangActionType.GetPenumpang
        });
      }
    };
  }
};

export { DaftarNamaPenumpangCommand, DaftarNamaPenumpangReducer, DaftarNamaPenumpangDefault };
